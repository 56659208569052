/* Initially, hide the extra text that
        can be revealed with the button */
#moreText {
  display: none;
}

img,
svg {
  /* fill: #a6a6a6; */
  width: 17px;
}

ul,
li {
  list-style: none;
  padding: 0px;
  margin: 0px;
}

.line {
  width: 0.1em;
  height: 16px;
  background-color: #999999;
  margin-left: 28px;
  position: absolute;
  bottom: 17px;
  opacity: 0.2;
}

.funfact-one {
  /* background-image: url("/public/assets/images/banner/Parallax-Image.jpg"); */
  height: 100%;
  /* background-color: rgb(27, 66, 27); */
  background-color: #05683b;
}

.i_svg {
  position: absolute;
  width: 80px;
  top: 5%;
  left: 50%;
  transform: translate(-50%, -50%);
  margin-bottom: 60px;
  margin-top: 12px;
}

.icon_svg {
  width: 60px;
  position: absolute;
  transform: translate(-50%, 50%);
  left: 50%;
  top: 50%;
  padding-top: 70px;
}

.icon_box {
  height: 370px;
  width: 450px;
}

.icon_mail {
  transform: translate(-50%, -50%);
  left: 50%;
  top: 45%;
  padding-top: 6px;
  font-size: 20px;
  text-align: center;
  padding: 0px 40px;
}

.get_img {
  /* background-image: url("/public/assets/images/banner/Get-In-Touch-BG.png"); */
  /* background-image: url("/public/assets/images/banner/G_I_T.png"); */
  /* background-image: url("/public/assets/images/banner/G_I_T Dark.png"); */
  background-image: url("/public/assets/images/banner/G_I_T Medium.png");
  background-color: #e0f3f6;
}

.carousel-root {
  width: 64% !important;
  margin: auto !important;
  margin-top: 2% !important;
}

.carousel .slide {
  background: #fff !important;
  color: black;
  height: 100%;
}

.carousel.carousel-slider .control-arrow {
  background: #fff !important;
  height: 50px !important;
  position: absolute;
  top: 35% !important;
}

.carousel.carousel-slider .control-arrow {
  height: 50px !important;
  position: absolute;
  top: 35% !important;
}

.carousel .control-prev.control-arrow {
  left: 0;
}

.carousel .control-arrow:before,
.carousel.carousel-slider .control-arrow:before {
  /* margin: 0 5px; */
  /* display: inline-block; */
  width: 1.1vw;
  height: 1.1vw;
  transform: rotate(45deg);
}

.carousel .control-prev.control-arrow:before {
  border-left: 2px solid #000 !important;
  border-bottom: 2px solid #000 !important;
}

.carousel .control-arrow:before,
.carousel.carousel-slider .control-arrow:before {
  border-top: none !important;
  border-right: none !important;
}

.carousel .control-next.control-arrow:before {
  border-left: none !important;
  border-bottom: none !important;
}

.carousel .control-arrow:before,
.carousel.carousel-slider .control-arrow:before {
  /* display: inline-block; */
  width: 1.1vw;
  height: 1.1vw;
  transform: rotate(45deg);
}

.carousel .control-next.control-arrow:before {
  border-right: 2px solid #000 !important;
  border-top: 2px solid #000 !important;
}

.carousel .slide img {
  width: 115px !important;
  border-radius: 50%;
}

.myCarousel {
  /* background: #fafafa; */
  margin-top: -6%;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  padding-top: 6%;
  /* padding-bottom: 4%; */
  padding-left: 5%;
  padding-right: 5%;
  height: 100%;
}

.carousel .control-dots {
  padding-left: 5px !important;
  outline: 0;
  bottom: 5% !important;
}

.myCarousel h3 {
  color: #222;
  font-weight: 100;
  letter-spacing: 0.3px;
  margin-bottom: -18px;
  font-weight: 700;
  font-size: 20px;
}

/* .myCarousel h4 {
            margin-top: 0;
            padding-top: 0;
            font-weight: 500;
            color: #787878;
            font-size: 14px;
        } */

.myCarousel p {
  /* font-weight: 400 !important; */
  line-height: 26px !important;
  color: #888888;
  font-size: 17px;
  font-family: "Inter";
  max-height: 100%;
  text-align: center;
  margin-top: 20px;
}

.myCarousel p:before {
  content: "";
  color: #888888;
  font-size: 26px;
  font-family: "Inter";
  font-weight: 300;
}

.myCarousel p:after {
  content: "";
  color: #888888;
  font-size: 26px;
  font-family: "Open Sans", sans-serif;
  font-weight: 100;
  line-height: 0;
}

.carousel.carousel-slider .control-arrow {
  /* background: #888 !important; */
  height: 50px !important;
  position: absolute;
  top: 35% !important;
}

.App {
  text-align: center;
}

/* our_expertise */
.set_img {
  /* background-image: url("/public/assets/images/img/our_expertise_bg.jpg"); */
  background-image: url("/public/assets/images/img/our_expertise_bg.png");
  top: 0%;
  width: 100%;
  height: 100%;
  background-color: #f5f5f5;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

/* banner_content */
.content_img {
  background-image: url("/public/assets/images/banner/About-Us-BG.png");
  top: 0;
  width: 100%;
  height: 100%;
  background-color: #e0f3f6;
  background-size: cover;
  background-repeat: no-repeat;
}

.content_img .inner-box p a {
  color: #000 !important;
}

.content_a {
  line-height: 30px;
  color: transparent;
  background: black;
  font-weight: 500;
  -webkit-background-clip: text !important;
  -webkit-text-fill-color: transparent;
}
.text-black {
  color: #000;
}
.current {
  border-bottom: 5px solid #05683b !important;
}
