.wid {
  width: 167px;
  top: 38%;
  left: 50%;
  transform: translate(-50%, -50%);
  position: absolute;
}

.stand {
  font-size: 11px;
}
.active {
  color: #329b7a;
}

.linkin {
  position: absolute;
  top: 54%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-family: "Inter";
}

.linkin a {
  /* color: white; */
  padding: 33px;
  text-align: center;
}

.linkin a:hover {
  color: #329b7a;
  text-decoration: none;
}
